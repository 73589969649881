.footer-mobile {
  width: 95%;
  padding-top: 15%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__contacts {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}
