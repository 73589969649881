.header-mobile {
  position: relative;
  z-index: 999;
  width: 95%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    width: 150px;
    & img {
      width: 100%;
    }
  }
}

.burger-menu {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 0 10px 20px;
  & > span {
    display: inline-block;
    height: 3px;
    width: 24px;
    background: #000;
    border-radius: 4px;

    &.true {
      visibility: hidden;

    }
  }
  &::after {
    display: inline-block;
    content: '';
    height: 3px;
    margin-top: 3px;
    background: black;
    border-radius: 4px;
    width: 24px;
    transition: all .2s ease-in-out;

  }
  &.true::after {
    margin-top: -3px;
    transform: rotate(45deg);
    transition: all .2s ease-in-out;

  }
  &.true::before {
    margin-bottom: -3px;
    transform: rotate(-45deg);
    transition: all .2s ease-in-out;

  }
  &::before {
    display: inline-block;
    content: '';
    height: 3px;
    margin-bottom: 3px;
    border-radius: 4px;
    background: black;
    width: 24px;
    transition: all .2s ease-in-out;
  }
}

.modal-menu {
  z-index: 998;
  position: absolute;
  height: 100vh;
  width: 100%;
  background: #fff;
  top: 0;

  &__wrapper {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .modal-menu__navigation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 4;
  }
  .modal-menu__contacts {
    display: flex;
    flex-direction: column;
    text-align: center;

    align-items: center;
    flex: 2;
  }
}
