@import "font";

@import "variables";

// set rem to 10px and global font family

html {
  font-family: "Inter", serif;
  font-size: 62.5%;
}



/* COMPONENTS */

.headline1 {
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 63px;
}

.headline2 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

.headline3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
}

.subtitle{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  &-sb {
    font-weight: 600;
  }
}

.regular {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  &-sb {
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
  }
}

.description {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

