@import "../variables";

$width: calc(100vw - 1200px);
$calculated: calc($width / 2);

@media (max-width: 1200px) {
  .introduction {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {
  .introduction {
    margin-left: $calculated;
  }
  .introduction-right {
    margin-right: $calculated;
  }
}

.mobile-width {
  width: 95%;
  margin: 5% auto;
}

.button-feedbacks {
  & button {
    padding-left: 0;
  }
}

.introduction-right {
  display: flex;
  justify-content: space-between;
}

.introduction {
  display: flex;
  justify-content: space-between;

  &-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
  }
  &__icons {
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__text {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: start;
  }

  &__image {
    width: 55%;

    & > img {
      width: 100%;
    }
  }
}

.icons {
  font-size: 3rem;
  display: flex;
  justify-content: start;
  &.icons-modal {
    justify-content: center;
    & > a {
      margin: 0 20px;
    }
  }
  & > a {
    margin-right: 40px;
    color: $blue;
  }
}
.home__goal {
  padding: 40px 0;
  max-width: 1200px;
  margin: 0 auto;
}
.home-width {
  max-width: 1200px;
  margin: 0 auto;
}

.questionnaire__text {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 49%;
  }
}
.home__requests {
  background: $lightblue;
}
.home__questionnaire {
  margin: 5% auto;
}

.questionnaire__open-form {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  & > h1 {
    width: 49%;
  }

  & > div {
    width: 49%;
  }
}

.home__images {
  display: flex;
  justify-content: space-between;
  & > img {
    width: 49%;

  }
}

.home__feedbacks {
  max-width: 1200px;
  margin: 5% auto;
}

.feedbacks__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedbacks__main {
  display: flex;
  height: 620px;
  justify-content: space-between;
}

.feedbacks__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
}

.home__format {
  display: flex;
  justify-content: space-between;
  align-items: end;

  & > h1 {
    width: 50%;
  }
}

.service__card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
  width: 46%;
  margin-top: 1%;
  padding: 20px;
  border-radius: 4px;

  & h1 {
    margin: 0;
    color: $white;
  }

  & button {
    padding-left: 0;
    margin-top: 3%;

  }
  &-wrapper {
    padding: 15px;
  }
}

.service__image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
}

.services__row {
  height: 341px;
  display: flex;
  justify-content: space-between;
}


