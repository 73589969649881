.service {

  &-width {
    max-width: 1200px;
    margin: 5% auto;
  }

  &__hrefs {

    & > a > button {
      font-size: 1.7rem;
      margin-top: 3%;
      padding: 0;
      text-align: left;
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main {
    display: flex;
    justify-content: space-between;

    &-left {
      width: 49%;
    }
    &-right {
      width: 48%;
      display: flex;
      flex-direction: column;
      align-items: center;

      &-no-image {
        width: 48%;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;

    & > div {
      width: 48%;
    }
  }
}
