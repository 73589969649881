.article-width {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

@media(max-width: 1300px) {
    .article-width {
        width: 95%;
        margin: 5% auto;
        max-width: initial;
    } 
  }
