@import 'base';

.articles-width {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}



.articles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-films__container {
        display: flex;
        flex-wrap: wrap;
    }
    .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .5s ease;
        background-color: rgba(1, 1, 1, 0.7);
        & > h1 {
            color: $white;
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

        }
    }


    .film {
        margin-bottom: 10%;
        display: flex;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        &-description {
            transition: opacity ease 0.2s;
            opacity: 0;
            margin-left: 20px;
            width: 60%;
        }
        & .film-image {
            transition: 0.3s ease-in-out;
            position: relative;
            width: 40%;
            & > img {
                display: block;
                width: 100%;
            }
            &:hover {
                transform: scale(1.02);
                .overlay {
                    opacity: 1;
                    transition: 0.3s ease-in-out;
                }
            }
        }
    }


}
.overlay-book {
    opacity: 0;
    transition: 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(1, 1, 1, 0.8);
    & > div {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        color: $white;
    }
}
.articles-books {
    &__container {
        display: flex;
        gap: 1%;
        flex-wrap: wrap;
    }
}
.book {
    flex: 1 0 280px;
    margin-bottom: 3%;
    position: relative;
    transition: 0.3s ease-in-out;
    & > img {
        display: block;
        width: 100%;
    }
    &:hover {
        transform: scale(1.02);
        transition: 0.3s ease-in-out;
        .overlay-book {
            opacity: 1;
            transition: 0.3s ease-in-out;
        }
    }
}
@media(max-width: 1300px) {
    .articles-width {
        width: 95%;
        margin: 0 auto;
    }
    .book {
        flex: 1 0 140px;
    }
    .articles {
        .film {
            margin-bottom: 5%;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex-grow: 1;
            width: 100%;
            &-description {
                width: 100%;
            }
            & .film-image {
                width: 100%;
            }
        }
    }
}


@keyframes show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }

}
