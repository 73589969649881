@import "../variables";

.about-width {
  max-width: 1200px;
  margin: 5% auto;
}

.about {
  &__motivation {
    margin-top: 10%;
    background: $lightblue;
  }

  &__prefooter {
    background: url("../../images/prefooter.png");
    background-size: cover;
    height: 520px;

    .prefooter__wrapper {
      padding: 60px 0;
      & > h1 {
        width: 60%;
      }
      & > button {
        margin: 5% 0;
      }
    }
  }
}
#timeline .timeline-item:after, #timeline .timeline-item:before {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0 10px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#timeline:before {
  content: "";
  width: 3px;
  height: 100%;
  background: #5068A7;
  left: 50%;
  top: 0;
  position: absolute;
}
#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}
#timeline .timeline-item {
  margin-bottom: 50px;
  position: relative;
}
#timeline .timeline-item .timeline-icon {
  background: #5068A7;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: hidden;
  margin-left: -23px;
  border-radius: 50%;
}
#timeline .timeline-item .timeline-icon svg {
  position: relative;
  top: 14px;
  left: 14px;
}
#timeline .timeline-item .timeline-content {
  width: 45%;
  background: #fff;
  padding: 20px;
}
#timeline .timeline-item .timeline-content.right {
  float: right;
}

@media screen and (max-width: 1300px) {
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
  }
  #timeline:before {
    left: 0;
  }
  #timeline .timeline-item .timeline-content {
    width: 85%;
    float: right;
  }
  #timeline .timeline-item .timeline-content:before, #timeline .timeline-item .timeline-content.right:before {
    left: 10%;
    margin-left: -6px;
    border-left: 0;
    border-right: 7px solid #5068A7;
  }
  #timeline .timeline-item .timeline-icon {
    left: 0;
  }
}

.certificates__row {
  overflow-x: scroll;
  height: 300px;
  display: flex;
}


