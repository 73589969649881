@import 'base';

#modal-form {
  position: relative;
  z-index: 999;
}

.form {
  background-color: rgba(0,0,0,0.5);

  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  & > form {
    position: relative;
    overflow: auto;
    background: #F5F8FF;
    width: 60%;
    height: 90%;
    padding: 30px;
    border-radius: 14px;


    & h1 {
      margin-bottom: 5px;
    }
    & > input, & > textarea {
      outline: none;
      font-size: 1.5rem;
      width: 100%;
      background: transparent;
      padding: 10px 0px;
      border: none;
      border-bottom: 1px solid #5068A7;
      transition: font-size .1s ease-in-out;

      &:focus {
        font-size: 25px;
        transition: font-size .1s ease-in-out;
      }
    }
    textarea {
      resize: none;
      height: 100px;
      padding: 20px 0;
    }
    select {
      font-size: 1.5rem;
    }
  }
}
.close-form {
  font-size: 2rem;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  transition: color .1s ease-in-out;
  &:hover {
    cursor: pointer;
    color: $gray;
    transition: color .1s ease-in-out;
  }
}

label {
  &#error {
    color: red;
  }
}
