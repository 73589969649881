@import "node_modules/normalize-scss/fork-versions/default/normalize";

a{
  text-decoration: none;
}

body {
  overflow-y: scroll;
}
@import "components";

