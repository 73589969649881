.footer {
  max-width: 1200px;
  width: 100%;
  margin: 5% auto 0;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;


  &__logo {
    width: 28%;
    & > h1 {
      opacity: 0.5;

    }
  }
  &__contacts {
    width: 44%;
    text-align: center;
  }
  &__menu {
    display: flex;
    width: 28%;
    justify-content: flex-end;

    &-column {
      margin-left: 20%;
      display: flex;
      flex-direction: column;
    }
  }
}


