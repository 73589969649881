@import 'base';

.header {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 20px 0;
  align-items: center;
  justify-content: space-between;
}

.menu {
  &__list {
    display: flex;

  }
  &__item{
    padding-left: 40px;
    list-style: none;
    & > a {
      text-decoration: none;
    }
  }
}

/*@media(max-width: 1300px) {
  .burger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    & > span {
      display: inline-block;
      height: 4px;
      width: 26px;
      background: #000;
      border-radius: 4px;

      &.true {
        visibility: hidden;

      }
    }
    &::after {
      display: inline-block;
      content: '';
      height: 4px;
      margin-top: 4px;
      background: black;
      border-radius: 4px;
      width: 26px;
      transition: all .2s ease-in-out;

    }
    &.true::after {
      margin-top: -4px;
      transform: rotate(45deg);
      transition: all .2s ease-in-out;

    }
    &.true::before {
      margin-bottom: -4px;
      transform: rotate(-45deg);
      transition: all .2s ease-in-out;

    }
    &::before {
      display: inline-block;
      content: '';
      height: 4px;
      margin-bottom: 4px;
      border-radius: 4px;
      background: black;
      width: 26px;
      transition: all .2s ease-in-out;
    }
  }
}*/
