.contacts {
  &-width {
    width: 1200px;
    margin: 5% auto;
  }

  .map__wrapper {
    display: flex;
    justify-content: space-between;
  }

  #map {
    width: 50%;
  }

  &__payment {
    & > h1 {
      width: 50%;

    }
  }

}
