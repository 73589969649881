/* COLORS */

$black: #15151F;
$white: #FFFFFF;
$gray: #757575;
$blue: #5068A7;
$lightblue: rgba(245, 248, 255, 0.66);



