.services {
  &-width {
    max-width: 1200px;
    margin: 5% auto;
  }

 .services__buttons {
   display: flex;
   justify-content: center;

   & > a:nth-child(1) {
     margin-right: 5%;
   }
 }
}
